import * as Sentry from "@sentry/browser";
import { sendServerAnalyticsEvent } from "../queries";
import { auth } from "../firebase";

type CustomWindow = Window & {
  gtag?: (command: string, target: string, ...args: any[]) => void;
  rdt?: (
    command: string,
    eventName: string,
    eventParams: Record<string, any>
  ) => void;
  fbq?: (
    command: string,
    eventName: string,
    eventParams?: Record<string, any>
  ) => void;
  dataLayer?: any;
};

export type EventParams = Record<string, string | number | boolean | undefined>;

const logAnalyticsEvent = (
  platform: string,
  logFunction: Function,
  win: CustomWindow = window
) => {
  try {
    if (!win.location.hostname.includes("localhost")) {
      logFunction();
    }
  } catch (error) {
    Sentry.captureException(
      new Error(`Failed to log ${platform} event: ${error}`)
    );
    console.error(`Failed to log ${platform} event:`, error);
  }
};

const getCoalescedEmail = (
  email: string | undefined = undefined,
  storage: Storage = localStorage
): string | undefined => {
  // Priority order:
  // 1. Firebase auth email
  // 2. Provided email parameter
  // 3. AI Photos email from storage
  // 4. Local storage email
  return auth.currentUser?.email || 
         email || 
         storage.getItem('ai_photos_email') ||
         storage.getItem('email') || 
         undefined;
};

export const logEvent = (
  eventName: string,
  product: string | undefined = undefined,
  providedEmail: string | undefined = undefined,
  eventParams: EventParams = {},
  eventCategory: string = "funnel",
  storage: Storage = localStorage,
  win: CustomWindow = window
): void => {
  if (typeof win !== "undefined") {
    const email = getCoalescedEmail(providedEmail, storage);
    const experimentValue = storage.getItem("experiment_group");
    const group =
      experimentValue !== null && experimentValue !== undefined
        ? parseInt(experimentValue, 10)
        : undefined;
    const experimentGroup =
      group === undefined ? undefined : group === 0 ? "A" : "B";
    const source = storage.getItem("utm_source");
    const campaign = storage.getItem("utm_campaign");

    const params: EventParams = {
      ...eventParams,
      event_category: eventCategory,
      ...(product && { product: product }),
      ...(email && { email: email }),
      ...(experimentGroup && { experiment_group: experimentGroup }),
      ...(source && { utm_source: source }),
      ...(campaign && { utm_campaign: campaign }),
    };

    console.log("LOGGING EVENT:: ", eventName, JSON.stringify(params));

    // Use gtag for Google Tag Manager events
    if (win.gtag) {
      win.gtag('event', eventName, params);
      console.log(
        `GTM event logged:`,
        eventName,
        JSON.stringify(params)
      );
    } else {
      console.warn("gtag is not available");
    }

    // Facebook Pixel tracking
    if (win.fbq) {
      logAnalyticsEvent("Facebook Pixel", () =>
        win.fbq!("track", eventName, params)
      );
      console.log(
        `Facebook Pixel event logged successfully:`,
        eventName,
        JSON.stringify(params)
      );
    } else {
      console.warn("fbq is not available");
    }

    if (eventCategory === "payment" && source === "reddit") {
      const redditParams: EventParams = {};
      redditParams.customEventName = eventName;
      redditParams.itemCount = 1;
      redditParams.value = params.amount;
      redditParams.currency = "USD";

      console.log(
        "LOGGING REDDIT EVENT:: ",
        redditParams.customEventName,
        JSON.stringify(redditParams)
      );

      if (win.rdt) {
        logAnalyticsEvent("Reddit", () =>
          win.rdt!("track", "Custom", redditParams)
        );
        console.log(
          `Reddit event logged successfully:`,
          redditParams.customEventName,
          JSON.stringify(redditParams)
        );
      } else {
        console.warn("rdt is not available");
      }
    }

    // Only log server-side for purchase activation events
    if (eventName === "purchase_activate") {
      console.log("ANALYTICS:: Starting purchase_activate_serverside tracking", { event: `${eventName}_serverside`, email, product, params });
      logEventServerSide(
        `${eventName}_serverside`,
        email,
        product,
        params,
        eventCategory,
        false,
        false
      ).then(() => {
        console.log("ANALYTICS:: purchase_activate_serverside logged successfully", { event: `${eventName}_serverside`, params });
      }).catch((error) => {
        console.error("ANALYTICS:: Failed to log purchase_activate_serverside:", error);
        Sentry.captureException(error);
      });
    }
  }
};

interface ServerEventParams extends EventParams {
  client_id?: string;
  user_id?: string;
}

// Helper function to get Google Analytics Client ID
export const getGoogleClientId_old = async (): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const win = window as CustomWindow;
    if (typeof win !== "undefined" && win.gtag) {
      const measurementId = import.meta.env.VITE_GA_MEASUREMENT_ID;
      try {
        const timeout = setTimeout(() => {
          const storageKey = "analytics_client_id";
          let clientId = localStorage.getItem(storageKey);

          if (!clientId) {
            clientId = crypto.randomUUID();
            localStorage.setItem(storageKey, clientId);
          }
          resolve(clientId);
        }, 2500);
        win.gtag("get", measurementId, "client_id", (clientId: string) => {
          clearTimeout(timeout);
          resolve(clientId);
        });
      } catch (error) {
        console.log("ERROR: No client ID returned by gtag", error);
        reject(error);
      }
    } else {
      console.warn("gtag not available for client ID retrieval");
      reject(undefined);
    }
  });
};

export const getGoogleClientId = async (): Promise<string> => {
  const win = window as CustomWindow;
  
  // 1. Try Google Analytics first
  if (win.gtag) {
    const measurementId = import.meta.env.VITE_GA_MEASUREMENT_ID;
    return new Promise((resolve) => {
      const timeout = setTimeout(() => {
        // 2. On timeout, try localStorage
        const storedId = localStorage.getItem("analytics_client_id");
        resolve(storedId || "0000099");  // 3. Final fallback
      }, 1000);

      win.gtag("get", measurementId, "client_id", (clientId: string) => {
        clearTimeout(timeout);
        if (clientId) {
          localStorage.setItem("analytics_client_id", clientId);
          resolve(clientId);
        } else {
          resolve("0000000");  // If GA returns but without an ID
        }
      });
    });
  }
  
  // 2. If GA not available, try localStorage
  const storedId = localStorage.getItem("analytics_client_id");
  return storedId || "0000000";  // 3. Final fallback
};

// New server-side tracking function
export const logEventServerSide = async (
  eventName: string,
  email: string | undefined,
  product: string | undefined = undefined,
  eventParams: ServerEventParams = {},
  eventCategory: string = "funnel",
  logMeta: boolean = false,
  logPosthog: boolean = false
): Promise<void> => {
  console.log(
    "ANALYTICS:: Starting server-side event tracking",
    {
      eventName,
      email,
      product,
      eventParams,
      eventCategory
    }
  );
  try {
    let clientId;
    try {
      clientId = await getGoogleClientId();
    } catch (error) {
      Sentry.captureException(
        new Error(`Failed to get Google Analytics Client ID: ${error}`)
      );
      clientId = "0000000"; // Fallback ID in case of failure
    }
    const source = localStorage.getItem("utm_source");
    const campaign = localStorage.getItem("utm_campaign");
    const params = {
      ...eventParams,
      client_id: clientId,
      ...(email && { email }),
      ...(source && { utm_source: source }),
      ...(campaign && { utm_campaign: campaign }),
    };
    console.log(
      "ANALYTICS:: Sending server-side event with params:",
      JSON.stringify(params, null, 2)
    );
    await sendServerAnalyticsEvent(
      eventName,
      params,
      product,
      eventCategory,
      logMeta,
      logPosthog
    );
    console.log(
      "ANALYTICS:: Server-side event logged successfully:",
      eventName,
      JSON.stringify(params)
    );
  } catch (error) {
    console.error(
      "ANALYTICS:: Failed to send server-side event:",
      eventName,
      error
    );
    Sentry.captureException(
      new Error(`Failed to send server-side analytics: ${error}`)
    );
    console.error(`Failed to send server-side analytics: ${error}`);
  }
};
