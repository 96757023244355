import toast from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ProfileWriter } from "./pages/ProfileWriter";
import { AIPhotos } from "./pages/aiphotos/AIPhotos";
import { AIPhotosDemo } from "./pages/aiphotos/AIPhotosDemo";
import { ChatAssistant } from "./pages/ChatAssistant";
import { ProfileReviewer } from "./pages/profile-review/ProfileReviewer";
import { ProfileReviewerDemo } from "./pages/review-demo/ProfileReviewerDemo";
import { useEffect } from "react";
import { auth } from "./firebase";
import { checkIsUserCreator, checkUserSubscription, createOrGetAuthUser } from "./queries";
import { useAuthStore } from "./stores/auth";
import { useUIStore } from "./stores/ui";
import Page from "./pages/Page";
import { Onboarding } from "./pages/Onboarding";
import { User, signOut } from "firebase/auth";
import { AuthState } from "./constants/auth";
import { useABTest } from './components/ab-testing/useABTest'
import { useReferralQueryParams } from "./pages/referral/useReferralQueryParams"
import { useCampaignQueryParams } from "./pages/useCampaignQueryParams"
import { useDiscountQueryParams } from "./pages/useDiscountQueryParams"
import { AIPhotoReviewWizard } from "./pages/photo-review/AIPhotoReviewWizard";
import { useWizardStore } from "./stores/wizard";
import { useUserStore } from "./stores/user";
import { UserSettings } from "./pages/UserSettings";
import { ProfileReviewerWelcome } from "./pages/welcome/ProfileReviewerWelcome";
import { AccountDeletionRequest } from "./pages/AccountDeletionRequest";
import NotFound from './pages/NotFound';
import { RefundPolicy } from "./pages/RefundPolicy";
import axios from "axios";
import { isValidEmail, getProductTypeFromPath } from './utils';
import { AIPhotosDemoReady } from "./pages/aiphotos/AIPhotosDemoReady";
const queryClient = new QueryClient();


// Init Sentry if we have an environment var for it
if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    sendDefaultPii: true,  // Enables capturing request payloads and headers
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/yourmove-api-production\.up\.railway\.app/,
      /^https:\/\/web\.yourmove\.ai\/api/,
      /^https:\/\/yourmove-production-jg7rg\.ondigitalocean\.app\/api/,
    ],
  });
}

function App() {
  const {
    setIsSubscribed,
    setAuthState
  } = useAuthStore();
  const {
    setStopScroll,
    setHideBottomNav,
    setHideTopBar,
  } = useUIStore();

  const { setIsCreator } = useUserStore()

  const { email, setEmail } = useWizardStore()

  useReferralQueryParams();
  useCampaignQueryParams();
  useDiscountQueryParams();

  const location = useLocation();

  // useABTest()

  // For hiding the bottom and side nav
  const hiddenBottomNavPages = ["/start", "/review-demo", "/ai-photo-demo-ready", "/ai-photo-demo"];
  const hiddenTopNavPages = ["/start", "/review-demo"];

  useEffect(() => {
    setHideBottomNav(hiddenBottomNavPages.includes(location.pathname));
    setHideTopBar(hiddenTopNavPages.includes(location.pathname));

    // When the URL changes, set the default stopScroll back to false
    setStopScroll(false);
  }, [location]);

  // Add new effect to watch subscription state
  const isSubscribed = useAuthStore(state => state.isSubscribed);

  // WEB ONLY CODE - sends email to mailcoach

  useEffect(() => {
    // Only run when user becomes subscribed
    if (isSubscribed) {
      try {
        const mailcoachUuid = localStorage.getItem('mailcoach_uuid');
        if (mailcoachUuid) {
          updateMailcoachSubscriber(mailcoachUuid);
        }
      } catch (error) {
        console.error('Error in Mailcoach subscription update:', error);
        Sentry.captureException(error);
      }
    }
  }, [isSubscribed]);

  // WEB ONLY CODE - sends email to mailcoach
  const updateMailcoachSubscriber = async (uuid: string) => {
    try {
      await axios.post(
        `${import.meta.env.VITE_MAIL_COACH_BASE_URL}/api/subscribers/${uuid}/tags`,
        {
          tags: ['subscribed']
        },
        {
          headers: {
            'Authorization': `Bearer ${import.meta.env.VITE_MAIL_COACH_API_KEY}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
    } catch (error) {
      // Log error but don't affect user experience
      console.error('Error updating Mailcoach subscriber:', error);
    }
  };

  const checkForSubscription = async () => {
    console.log('App:: checkForSubscription')
    const userEmail = auth.currentUser?.email ?? email
    if (userEmail) {
      const response = await checkUserSubscription(userEmail)
      setIsSubscribed(response.data.isSubscribed)

      // Wrap Mailcoach-related code in try/catch
      // WEB ONLY CODE - sends email to mailcoach
      try {
        if (response.data.isSubscribed) {
          const mailcoachUuid = localStorage.getItem('mailcoach_uuid');
          if (mailcoachUuid) {
            await updateMailcoachSubscriber(mailcoachUuid);
          }
        }
      } catch (error) {
      }
    }
  };

  const checkIfUserIsCreator = async () => {
    const userEmail = auth.currentUser?.email ?? email
    if (userEmail) {
      const response = await checkIsUserCreator(userEmail)
      setIsCreator(response.data.isCreator)
    }
  }

  // When the auth state changes, check if the user isSubscribed
  useEffect(() => {
    const createUser = async (user: User) => {
      const { uid, email = "" } = user || {};
      try {
        // create an account here using the id returned from the auth so we can map email to id.
        // fix for apple id issue
        await createOrGetAuthUser(uid, email as string);
        setEmail(email as string);
      } catch (error) {
        console.error("Error creating or retrieving user:", error);
        // Handle the error as needed, e.g., show a notification or log the error
        const errorMessage =
          "An error occurred while trying to fetch the user. Please try signing in again. If the problem persists, please contact support@yourmove.ai for assistance.";
        toast.error(errorMessage);
        signOut(auth);
      }
    };

    const handleAuthStateChange = async (user: User | null) => {
      if (user) {
        await createUser(user);
        setAuthState(AuthState.Authenticated);
        await checkForSubscription();
        await checkIfUserIsCreator();
      } else {
        setAuthState(AuthState.NotAuthenticated);
        setIsCreator(false);
        await checkForSubscription();
      }
    };

    const unsubscribe = auth.onAuthStateChanged(handleAuthStateChange);
    return () => unsubscribe();
  }, []);

  // WEB ONLY CODE - sends email to mailcoach
  useEffect(() => {
    const email = localStorage.getItem('email');
    const firstProduct = localStorage.getItem('firstProduct');

    if (email && firstProduct && isValidEmail(email)) {
      const productType = getProductTypeFromPath(firstProduct);
      const mailcoachEndpoint = `${import.meta.env.VITE_MAIL_COACH_BASE_URL}/api/email-lists/${import.meta.env.VITE_MAIL_COACH_MAILING_LIST_ID}/subscribers`;
      
      const mailcoachPayload = {
        email: email.toLowerCase().trim(),
        extra_attributes: {
          first_product: productType,
        },
        tags: [productType],
        skip_confirmation: true
      };

      console.log('Sending to Mailcoach:', {
        url: mailcoachEndpoint,
        payload: mailcoachPayload
      });

      axios.post(
        mailcoachEndpoint,
        mailcoachPayload,
        {
          headers: {
            'Authorization': `Bearer ${import.meta.env.VITE_MAIL_COACH_API_KEY}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
        .then(response => {
          // Log successful response
          // console.log('Mailcoach success:', response.data);

          // Store UUID if it exists in response
          if (response.data?.data?.uuid) {
            localStorage.setItem('mailcoach_uuid', response.data.data.uuid);
            console.log('Mailcoach UUID stored:', response.data.data.uuid);
          }
        })
        .catch(error => {
          // Log error but don't show to user
          console.error('Mailcoach error:', {
            status: error?.response?.status,
            data: error?.response?.data,
            message: error.message
          });
          Sentry.captureException(error);
        });
    }
  }, []);

  // Preserve existing Tolt integration
  useEffect(() => {
    if (!auth.currentUser && email && email.length > 0 && window.tolt_referral) {
      window.tolt.signup(email)
    }
  }, [email])

  // TODO: Check change in email and check subscription status here instead 
  // of the products individually

  // Add effect to store last valid page
  useEffect(() => {
    // List of valid pages to remember (excluding utility/special routes)
    const validPages = [
      '/chat-assistant',
      '/profile-writer',
      '/profile-review',
      '/ai-photo',
    ];
    
    // Only store the page if it's in our valid list
    if (validPages.includes(location.pathname)) {
      localStorage.setItem('lastValidPage', location.pathname);
    }
  }, [location]);

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route
          path="/"
          element={
            localStorage.getItem("hasOnboarded") ? (
              <Navigate to={localStorage.getItem('lastValidPage') || '/chat-assistant'} />
            ) : (
              <Navigate to="/start" />
            )
          }
        />
        <Route
          path="/start"
          element={
            <Page title="Start">
              <Onboarding />
            </Page>
          }
        />
        <Route
          path="/chat-assistant"
          element={
            <Page title="Chat Assistant">
              <ChatAssistant />
            </Page>
          }
        />
        <Route
          path="/profile-writer"
          element={
            <Page title="Profile Writer">
              <ProfileWriter />
            </Page>
          }
        />
        <Route
          path="/profile-review"
          element={
            <Page title="Profile Review">
              <ProfileReviewer />
            </Page>
          }
        />
        <Route
          path="/ai-photo"
          element={
            <Page title="AI Enhanced Photos">
              <AIPhotos />
            </Page>
          }
        />
        <Route
          path="/ai-photo-review"
          element={
            <Page title="AI Photo Review">
              <AIPhotoReviewWizard />
            </Page>
          }
        />
        <Route
          path="/user-settings"
          element={
            <Page title="User Settings">
              <UserSettings />
            </Page>
          }
        />
        <Route
          path="/welcome"
          element={
            <Page title="Welcome">
              <ProfileReviewerWelcome />
            </Page>
          }
        />
        <Route
          path="/review-demo"
          element={
            <Page title="Review Demo">
              <ProfileReviewerDemo />
            </Page>
          }
        />
        <Route
          path="/request-delete"
          element={
            <Page title="Request Account Deletion">
              <AccountDeletionRequest />
            </Page>
          }
        />
        {/* <Route
          path="/user-referrals"
          element={
            <Page title="YourMove - Referrals">
              <UserReferrals />
            </Page>
          }
        /> */}
        <Route
          path="/refund-policy"
          element={<RefundPolicy />}
        />
        <Route
          path="/ai-photo-demo"
          element={
            <Page title="AI Photos Demo">
              <AIPhotosDemo />
            </Page>
          }
        />
        <Route
          path="/ai-photo-demo-ready"
          element={
            <Page title="AI Photos Demo Ready">
              <AIPhotosDemoReady />
            </Page>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
