import axios, { AxiosResponse } from "axios";
import {
  ClientSecretResponse,
  CreateSubscriptionRequest,
} from "./models/payment";
import {
  FeedbackRequest,
  HasUserPaidResponse,
  ProfileRequest,
  ProfileResponse,
  Prompt,
} from "./models/profile";
import { AIPhotosPlan, ProductType } from "./constants/payments";
import { usePaymentStore } from "./stores/payment";
import { useChatStore } from "./stores/chat";
import * as Sentry from "@sentry/react";
import toast from "react-hot-toast";
import { getEndpoint } from "./utils";
import { useUIStore } from "./stores/ui";

const BASE_URL = import.meta.env.VITE_API_BASE_URL;

// temporary for testing
axios.defaults.headers.common["ngrok-skip-browser-warning"] = "something";

axios.defaults.timeout = 240000;

const retryCount = 3;
const retryDelay = 2000; // Delay between retries in milliseconds

axios.interceptors.response.use(
  (response) => {
    // Was successful - return the response
    return response;
  },
  (error) => {
    const config = error.config;

    const shouldRetry =
      (error.response && error.response.status === 500) ||
      error.message === "Network Error";

    if (shouldRetry) {
      // Check if we've already tried to retry this request
      if (!config.__retryCount) {
        config.__retryCount = 0;
      }

      // Check if we've reached the max retry count
      if (config.__retryCount < retryCount) {
        // Increase the retry count
        config.__retryCount += 1;

        // Create new promise to handle exponential backoff
        const backoff = new Promise((resolve) => {
          setTimeout(() => {
            resolve(null);
          }, retryDelay || 1);
        });

        // Return the promise in which recalls axios to retry the request
        return backoff.then(() => {
          return axios(config);
        });
      }
    }

    // If it's a chat error
    if (error.config.url.includes("chat")) {
      // If it was a 429 for the chat assistant
      if (error?.response?.status === 429) {
        // If they are signed in, redirect to premium
        toast.error(
          "You have ran out of free messages for today. Upgrade for unlimited messages and more"
        );
        usePaymentStore
          .getState()
          .setProduct("chat_assistant")
        usePaymentStore
          .getState()
          .setOpenPaymentModal(true)
        useChatStore.getState().setSendingMessage(false);
      } else {
        useChatStore.getState().setSendingMessage(false);
        toast.error(
          "There was an issue with our AI. Please wait a few minutes and try again."
        );
      }
    } else {
      // If it timed out
      if (error.code === "ECONNABORTED") {
        useUIStore
          .getState()
          .setError(
            "Our AI is taking longer than usual. Please wait a few minutes and try again."
          );
        // Handle the timeout error here
      } else if (
        // Add mailcoach to the list of endpoints that shouldn't trigger the error state
        getEndpoint(error.config?.url) !== 'validate-promo-code' && 
        !error.config.url.includes('yourmove.mailcoach.app') &&
        !error.config.url.includes('posthog')
      ) {
        useUIStore
          .getState()
          .setError(
            "There was an issue with our AI. Please wait a few minutes and try again."
          );
      }
      // Log error details to Sentry or console
      const errorDetails = {
        url: error.config.url,
        method: error.config.method,
        statusCode: error?.response?.status,
        statusText: error?.response?.statusText,
        headers: error.config.headers,
        data: error.config.data,
        errorMessage: error?.response?.data?.message,
      };

      console.error("Axios request failed:", error);
      Sentry.captureException(new Error("Axios request failed"), {
        extra: errorDetails,
      });
    }
    return Promise.reject(error);
  }
);

export const getClientSecret = (
  email: string,
  product: string,
  group: number = 0,
  toltReferral: string | undefined = undefined
): Promise<AxiosResponse<ClientSecretResponse>> => {
  const source = localStorage.getItem('utm_source')
  const campaign = localStorage.getItem('utm_campaign')
  const params = {
    email,
    product,
    group,
    ...(source && { source: source }),
    ...(campaign && { campaign: campaign }),
    toltReferral
  }

  return axios.post(`${BASE_URL}/client-secret`, params);
};

export const getPrompts = (
  app: string
): Promise<AxiosResponse<Array<Prompt>>> => {
  return axios.get(`${BASE_URL}/prompts?app=${app}`);
};

export const generateProfile = (
  profileRequest: ProfileRequest
): Promise<AxiosResponse<any>> => {
  return axios.post(`${BASE_URL}/profile`, profileRequest);
};

export const generateSingleProfileResponse = (
  profileRequest: ProfileRequest,
  prompt: string
): Promise<AxiosResponse<ProfileResponse>> => {
  return axios.post(
    `${BASE_URL}/profile/prompt?prompt=${prompt}`,
    profileRequest
  );
};

export const hasUserPaid = (
  email: string,
  products: Array<ProductType>
): Promise<AxiosResponse<HasUserPaidResponse>> => {
  return axios.post(`${BASE_URL}/user/has-paid`, { email, products });
};

export const submitFeedback = (feedbackRequest: FeedbackRequest) => {
  feedbackRequest.platform = 'web';
  return axios.post(`${BASE_URL}/email/feedback`, feedbackRequest);
};

export const createCopy = (email: string, prompt: string, response: string) => {
  return axios.post(
    `${BASE_URL}/profile/prompt/copy`,
    {
      email,
      prompt,
      response,
    },
    { timeout: 600000 }
  );
};

export const sendChatText = (
  type: string,
  style: string,
  query: string,
  curiosityMode: boolean,
  accessToken?: string,
  email?: string
) => {
  const headers = {
    "Content-Type": "application/json",
  };
  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return axios.post(
    `${BASE_URL}/chat/text`,
    {
      type,
      style,
      query,
      curiosityMode,
      email
    },
    {
      headers,
    }
  );
};

export const uploadFiles = (files: FileList) => {
  // Define the validation criteria
  const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp', 'image/gif'];
  const maxFileSize = 20 * 1024 * 1024; // 20MB in bytes
  const formData = new FormData();

  Array.from(files).forEach((file) => {
    formData.append("files", file);
  });

  return axios.post(`${BASE_URL}/files/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const sendChatImage = (
  type: string,
  style: string,
  // query: string,
  curiosityMode: boolean,
  // pass the URL to the server so it will pass it back
  image: string | null,
  file: File | null,
  recentQuery: string | null,
  accessToken?: string,
  email?: string
) => {
  const formData = new FormData();

  formData.append("type", type);
  formData.append("style", style);

  if (recentQuery) {
    formData.append("recentQuery", JSON.stringify(recentQuery));
  }

  formData.append("curiosityMode", curiosityMode.toString());

  // Append the file
  if (file) {
    formData.append("file", file);
  }

  // If an image URL was sent
  if (image) {
    formData.append("image", image);
  }

  if (email) formData.append("email", email)

  const headers = {
    "Content-Type": "multipart/form-data",
  };
  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }

  return axios.post(`${BASE_URL}/chat/image`, formData, {
    headers,
  });
};

export const generateProfileReview = (
  email: string,
  screenshots: Array<string>
) => {
  return axios.post(
    `${BASE_URL}/profile-reviewer`,
    { email, screenshots },
    { timeout: 120000 }
  );
};

export const createSubscription = (
  createSubscriptionRequest: CreateSubscriptionRequest
) => {
  const source = localStorage.getItem('utm_source')
  const campaign = localStorage.getItem('utm_campaign')
  if (source) createSubscriptionRequest.source = source
  if (campaign) createSubscriptionRequest.campaign = campaign

  return axios.post(`${BASE_URL}/subscribe`, createSubscriptionRequest);
};

export const checkIfUserSubscribed = (idToken: string) => {
  return axios.get(`${BASE_URL}/user/is-subscribed`, {
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
  });
};

export const checkIfUserRequiresMigration = (email: string) => {
  return axios.get(`${BASE_URL}/user/requires-migration?email=${email}`);
};

export const migrateUser = (email: string, password?: string) => {
  const body: any = { email };
  if (password) {
    body["password"] = password;
  }
  return axios.post(`${BASE_URL}/user/migrate`, body);
};

export const createOrGetAuthUser = (uid: string, email: string) => {
  const body: any = { uid, email };
  return axios.post(`${BASE_URL}/user/createAuthUser`, body);
};

export const setUserSubscription = (email: string, subscriptionId: string) => {
  const body = { email, subscription_id: subscriptionId };
  return axios.post(`${BASE_URL}/set-subscription`, body);
}

export const paymentIntentForAIPhotos = (email: string, plan: AIPhotosPlan, promoCode?: string) => {
  const body: { email: string; plan: AIPhotosPlan; promoCode?: string } = {
    email,
    plan
  };
  
  if (promoCode) {
    body.promoCode = promoCode;
  }
  
  return axios.post(`${BASE_URL}/payment-intent-ai-photos`, body);
};

export const paymentIntentForLockedAIPhotos = (email: string) => {
  const body = {
    email
  }
  return axios.post(`${BASE_URL}/payment-intent-locked-ai-photos`, body);
}

export const fetchUserReferralCode = (email: string) => {
  const body = {
    email
  }
  return axios.post(`${BASE_URL}/user/get-referral-code`, body);
}

export const addUserReferral = (userId: string, userEmail: string, referralCode: string) => {
  const body = {
    userId,
    userEmail,
    referralCode
  }
  return axios.post(`${BASE_URL}/user/add-referral`, body);
}

export const fetchReferralsCount = (referralCode: string) => {
  const body = {
    referralCode
  }
  return axios.post(`${BASE_URL}/user/fetch-referrals-count`, body);
}

export const claimSubscription = (email: string) => {
  const body = {
    email
  }
  return axios.post(`${BASE_URL}/user/claim-referral-subscription`, body);
}

export const fetchReferralSubscriptionStatus = (email: string) => {
  const body = {
    email
  }
  return axios.post(`${BASE_URL}/user/fetch-referral-subscription-status`, body);
}

export const unsubscribeDiscountOffer = (email: string) => {
  const body = { email }
  return axios.post(`${BASE_URL}/unsubscribe-discount-offer`, body);
}

export const checkUserSubscription = (email: string) => {
  return axios.get(`${BASE_URL}/user/subscribed`, { params: { email } });
};

export const validatePromoCode = (promoCode: string) => {
  const body = { promo_code: promoCode }
  return axios.post(`${BASE_URL}/validate-promo-code`, body);
};

export const reviewPhoto = (email: string, url: string) => {
  const body = { email, photo: url }
  return axios.post(`${BASE_URL}/photo-review`, body);
};

export const fetchPhotoReviewsCount = (email: string) => {
  return axios.get(`${BASE_URL}/fetch-photo-reviews-count?email=${email}`);
};

export const fetchPhotoReviews = (email: string, loadMore: string | undefined = undefined) => {
  const url = new URL(`${BASE_URL}/photo-reviews`);
  url.searchParams.append('email', email);
  if (loadMore) url.searchParams.append('lastVisible', loadMore);

  return axios.get(url.toString());
};

export const checkIsUserCreator = (email: string) => {
  return axios.get(`${BASE_URL}/user/is_creator?email=${email}`);
}

export const requestAccountDeletion = (email: string) => {
  const body = { email }
  return axios.post(`${BASE_URL}/request-account-deletion`, body);
};

export const updateUserSubscription = (email: string, subscriptionExpiry: number) => {
  const body = { email, subscriptionExpiry }
  return axios.post(`${BASE_URL}/user/update-subscription`, body);
}

export const fetchAIPhotos = (email: string) => {
  return axios.get(`${BASE_URL}/ai_photos?email=${email}`);
}

export const createAIPhotosRequest = (email: string, photos: string[], title: string, batchSize: number, lockedPhotos: number) => {
  const body = { email, photos, title, batch_size: batchSize, locked_photos: lockedPhotos }
  console.log('Creating AI Photos Request::', {
    email,
    photoCount: photos.length,
    title,
    batchSize,
    lockedPhotos,
    timestamp: new Date().toISOString()
  });
  return axios.post(`${BASE_URL}/ai_photos_request`, body);
}

export const updateLockedAIPhotos = (email: string, title: string) => {
  const body = { email, title }
  return axios.post(`${BASE_URL}/update_locked_photos`, body);
}

export const createTune = (email: string, title: string, imageUrls: string[], name: string, token: string, baseTuneId: string) => {
  const body = { email, title, image_urls: imageUrls, name, token, base_tune_id: baseTuneId }
  return axios.post(`${BASE_URL}/train_tune`, body);
}

export const generatePrompts = (email: string, gender: string, tuneId: string, batchSize: number) => {
  const body = { email, gender, tune_id: tuneId, batch_size: batchSize }
  return axios.post(`${BASE_URL}/generate_prompts`, body);
}

export const addToMailingList = async (email: string, productType: string) => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_MAIL_COACH_BASE_URL}/api/email-lists/${import.meta.env.VITE_MAIL_COACH_MAILING_LIST_ID}/subscribers`,
      {
        email: email.toLowerCase().trim(),
        extra_attributes: {
          first_product: productType,
        },
        tags: [productType],
        skip_confirmation: true
      },
      {
        headers: {
          'Authorization': `Bearer ${import.meta.env.VITE_MAIL_COACH_API_KEY}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );

    // Store UUID if it exists in response
    if (response.data?.data?.uuid) {
      localStorage.setItem('mailcoach_uuid', response.data.data.uuid);
      // console.log('Mailcoach UUID stored:', response.data.data.uuid);
    }

    return response;
  } catch (error) {
    console.error('Mailcoach error:', error);
  }
};

export const updateMailcoachSubscriber = async (tags: string[]) => {
  try {
    // console.log('Attemping mailcoach update - 2');

    const uuid = localStorage.getItem('mailcoach_uuid');
    if (!uuid) {
      // console.log('No Mailcoach UUID found in localStorage');
      return;
    }

    const response = await axios.post(
      `${import.meta.env.VITE_MAIL_COACH_BASE_URL}/api/subscribers/${uuid}/tags`,
      { tags },
      {
        headers: {
          'Authorization': `Bearer ${import.meta.env.VITE_MAIL_COACH_API_KEY}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );
    return response;
  } catch (error) {
    console.error('Error updating Mailcoach subscriber:', error);
  }
};

// Add the server-side analytics event function
export const sendServerAnalyticsEvent = async (
  eventName: string,
  eventParams: Record<string, any>,
  product: string | undefined,
  eventCategory: string,
  logMeta: boolean = false,
  logPosthog: boolean = false,
) => {
  console.log('ANALYTICS:: Sending server analytics event', {
    eventName,
    eventParams,
    product,
    eventCategory,
    logMeta,
    logPosthog
  });
  return axios.post(`${BASE_URL}/api/log`, {
    eventName,
    product,
    eventParams,
    eventCategory,
    logMeta,
    logPosthog
  }, {
    headers: {
      'Content-Type': 'application/json',
      'X-Custom-Analytics': 'true',
    }
  });
};

export const logWriterAppRequest = (app_requested: string) => {
  return axios.post(`${BASE_URL}/profile/app-request`, null, {
    params: { app_requested: app_requested.toLowerCase() }
  });
};
